<template>
  <div class="page">
    <span
      ref="refreshBtn"
      onclick="window.open(document.getElementById('refresh_box').src,'refresh_name','')"
      style="display: none"
      >刷新</span
    >
    <div class="login-code">
      <p class="tit">小说投放系统</p>
      <div v-loading="loading">
        <iframe
          class="webview"
          id="refresh_box"
          name="refresh_name"
          src="https://weadmin.chongqingtusi.com/feishu_login/login.html?v=0.10"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { setCookie } from "../assets/js/cookie";
import { mapActions } from "vuex";
import { login, getUserIsFeedback } from "../api/user";
import { getOfficialList, getAllUsers } from "../api/account";

export default {
  data() {
    return {
      loading: false,
      disabled: false,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     if (typeof window.addEventListener != 'undefined') {
  //       window.addEventListener('message', vm.handleMessage, false);
  //     } else if (typeof window.attachEvent != 'undefined') {
  //       window.attachEvent('onmessage', vm.handleMessage);
  //     }else {
  //       console.log("message事件绑定失败！")
  //     }
  //   })
  // },
  mounted() {
    this.$nextTick(() => {
      window.onmessage = this.handleMessage;
    });
  },
  methods: {
    handleMessage(event) {
      const origin = event.origin;
      if (origin === "https://weadmin.chongqingtusi.com") {
        // 判断是否来自ddLogin扫码事件。
        const loginTmpCode = event.data; // 拿到loginTmpCode后就可以在这里构造跳转链接进行跳转了
        this.codeLogin(loginTmpCode);
      }
    },
    codeLogin(code) {
      this.loading = true;
      login({ tmpAuthCode: code })
        .then((res) => {
          if (res) {
            const { token, ...userInfo } = res;
            if (!("homeRole" in userInfo)) {
              userInfo.homeRole = "999";
            }
            setCookie("cps_token", token);
            setCookie("userInfo", JSON.stringify(userInfo));
            this.setUserInfo(userInfo);
            this.$notify({
              title: "成功",
              message: "登录成功",
              type: "success",
            });
            this.resetHistory();
            // this.$router.replace('/')
            // location.reload();
            getOfficialList().then((res) => {
              this.setOfficialList(res);
            });
            getAllUsers().then((res) => {
              this.setUserList(res);
            });
            // console.log(userInfo, "userInfo in login");
            // userInfo.id 319
            // 获取是否填写反馈
            getUserIsFeedback()
              .then((res) => {
                let tmpFlag = res ? true : false;
                this.$store.dispatch("setFeedBackFlag", tmpFlag);
                console.log(
                  this.$store.state.isFeedBack,
                  "isFeedBack in login"
                );
              })
              .finally(() => {
                location.reload();
              });
          } else {
            setTimeout(() => {
              this.codeLogin(code);
            }, 250);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$refs.refreshBtn.click();
        });
    },
    ...mapActions({
      setUserInfo: "setUserInfo",
      setUserList: "setUserList",
      setOfficialList: "setOfficialList",
      resetHistory: "resetHistory",
    }),
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
.page {
  background: url("../assets/bg.jpg") no-repeat center;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap {
  width: 450px;
  height: 450px;
  margin: auto;
  background: #ffffff;
  text-align: center;
}

.webview {
  width: 320px;
  height: 320px;
}

.login-code {
  background-color: #fff;
  border-radius: 6px;
}

.tit {
  text-align: center;
  margin-top: 20px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  color: #1c9399;
  border-bottom: 1px solid #1c9399;
}
</style>
